import useWindowSize from "@/hooks/useWindowSize";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useAppSelector } from "@/store/store";
import clsx from "clsx";
import dynamic from "next/dynamic";
import React from "react";

const Animation = dynamic(() => import("./animation"));

interface AnimationWrapperProps {
  children: React.ReactNode;
  animationIn?: string;
  animationOut?: string;
  animationHover?: string;
}

const AnimationWrapper = (props: AnimationWrapperProps) => {
  const { width } = useWindowSize();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  let animationsActive = false;

  if (globalConfig?.responsive?.breakpoints?.mobile) {
    animationsActive =
      width > globalConfig?.responsive?.breakpoints?.mobile! ||
      (globalConfig?.animation?.animationsMobile &&
        width < globalConfig?.responsive?.breakpoints?.mobile);
  }

  const animationHover =
    !editView && props.animationHover !== "none" ? props.animationHover : "";

  return (
    <>
      {!editView &&
      props.animationIn &&
      props.animationIn !== "none" &&
      animationsActive ? (
        <Animation
          animationIn={props.animationIn}
          animationOut={props.animationOut}
          animationHover={animationHover}
        >
          {props.children}
        </Animation>
      ) : (
        <div className={clsx("animation-wrapper", animationHover)}>
          {props.children}
        </div>
      )}
    </>
  );
};

export default AnimationWrapper;
