import { determineStoreValueId } from "@/services/ceSettings/ceSettingsService";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { STORE_BACKGROUND_IMAGE_CLASS_PREFIX } from "@/utils/constants";

import { globalConfig } from "@/services/globalConfig/globalConfigService";

export default function useStrBackgroundImage(
  backgroundImageStoreSetting?: StoreSetting,
  ceSettings?: StoreSetting,
  globalConfigInstead: boolean | undefined = false
) {
  if (globalConfigInstead) {
    ceSettings = globalConfig?.layout.bgImages ?? undefined;
  }

  const backgroundImageId = determineStoreValueId(
    backgroundImageStoreSetting,
    ceSettings
  );

  const backgroundImageClassName = backgroundImageId
    ? `${STORE_BACKGROUND_IMAGE_CLASS_PREFIX}${backgroundImageId}`
    : "";

  return { backgroundImageClassName };
}
