import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "@/store/store";
import { cmsGetDraftPageAndStartEditModeThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { enableBeforeunloadAction } from "@/store/slices/general/generalSlice";
import { removeUrlQueryParams } from "@/utils/urlUtil";

interface EditModeState {
  url: string;
  locale: string;
}

export default function useStartEditMode(startEditMode: boolean) {
  // prevent triggering edit mode everytime you leave the edit mode
  const [editModeStartedOnce, setEditModeStartedOnce] = useState(false);
  // remember the path where the auto start edit mode was triggered
  const [editModeStartedOncePath, setEditModeStartedOncePath] = useState<
    EditModeState | undefined
  >(undefined);
  const router = useRouter();
  const dispatch = useAppDispatch();

  const isCmsUserAuthenticated = useAppSelector(
    (state) => state.cmsUser.isCmsUserAuthenticated
  );
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const currentContext = useAppSelector(
    (state) => state.cmsGeneral.currentContext
  );

  useEffect(() => {
    let url = removeUrlQueryParams(router.asPath);
    if (
      startEditMode &&
      isCmsUserAuthenticated &&
      !editMode &&
      // only start once or if the above is true and the router path has changed
      // then the editModeStartedOnce state will still be true
      (!editModeStartedOnce ||
        editModeStartedOncePath?.url !== url ||
        (editModeStartedOncePath?.url === url &&
          editModeStartedOncePath?.locale !== router.locale!))
    ) {
      if (currentContext) {
        setEditModeStartedOnce(true);
        setEditModeStartedOncePath({
          url: url,
          locale: router.locale!,
        });
        dispatch(cmsGetDraftPageAndStartEditModeThunk(url));
        dispatch(enableBeforeunloadAction());
      }
    }
  }, [
    dispatch,
    startEditMode,
    editModeStartedOncePath,
    editModeStartedOnce,
    isCmsUserAuthenticated,
    editMode,
    currentContext,
    router,
  ]);
}
