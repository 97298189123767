import useStrColor from "@/hooks/useStrColor";

interface ContentElementWrapperStyleScopedProps {
  scopedSelector: string;
  content: any;
  ceSettings: any;
}

export default function ContentElementWrapperStyleScoped(
  props: ContentElementWrapperStyleScopedProps
) {
  const { colorCssVar } = useStrColor(
    props.content.cfgStrBackgroundColor,
    props.ceSettings?.bgColors
  );
  
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        ${colorCssVar && `background-color: ${colorCssVar}`}
      }
    `}</style>
  );
}
