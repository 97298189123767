import { PbIcon } from "@/components/pbIcon/PbIcon";

export interface CmsAddButtonProps {
  title: string;
  text: string;
  onClick: () => void;
  /**
   * default height is 150 (px) the min value is 60 if
   * you try to go below 60 it will be reset to 60
   */
  height?: number;
  maxWidth?: number;
  /**
   * If true the plus icon will not be shown
   */
  disablePlusIcon?: boolean;
}

/**
 * The dashed border button for creating (adding) content
 * within edit mode/editing a page.
 *
 * Do not use this button for a normal button usecase
 * use <PbButton> instead
 *
 * @param props
 * @returns
 */
export default function CmsAddButton(props: CmsAddButtonProps) {
  let height = props.height ?? 150;
  if (height < 60) {
    height = 60;
  }
  const maxWidth = props.maxWidth ? props.maxWidth + "px" : "100%";

  return (
    <>
      <div className="pb-dashed-cms-add-button">
        <button
          title={props.title}
          onClick={() => {
            props.onClick();
          }}
        >
          <span className="text">{props.text}</span>
          <span className="icon">
            <PbIcon name={"plus-light"} height={40} width={40} />
          </span>
        </button>
      </div>
      <style jsx>{`
        .pb-dashed-cms-add-button {
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            font-size: 2.4rem;
            font-weight: 400;
            margin-top: 4rem;
            border: 4px dashed var(--pb-cms-color);
            height: ${height}px;
            width: ${maxWidth};
            background-color: unset;
            overflow: hidden;

            .text {
              color: var(--pb-cms-color);
              display: block;
              line-height: 1.1;
            }
            .icon {
              display: none;
            }

            &:hover {
              .text {
                display: ${props.disablePlusIcon ? "block" : "none"};
              }
              .icon {
                display: ${props.disablePlusIcon ? "none" : "block"};
                :global(svg) {
                  fill: var(--pb-cms-color);
                }
              }
            }
          }
        }
      `}</style>
    </>
  );
}
