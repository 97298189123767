export default function CmsAddContentModalStyle() {
  return (
    <style jsx global>{`
      .add-content-modal-wrapper {
        .button-row {
          display: grid;
          grid-template-columns: repeat(auto-fill, 180px);
          justify-content: space-evenly;
          width: 100%;
          .button-col {
            padding: 1rem;
            display: flex;
            max-width: 100%;

            .add-content-element-button {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              width: 160px;
              height: 160px;
              box-shadow: 1px 1px 3px -1px black;
              background-color: white;
              border: none;

              :hover {
                background-color: #b3b3b3;
                color: white;
                .button-svg {
                  svg {
                    stroke: white;
                    fill: white;
                  }
                }
              }
              .button-svg {
                display: block;
                margin-top: auto;
                margin-bottom: auto;

                svg {
                  stroke: black;
                }
              }
              .button-title {
                padding: 0.5rem;
                display: block;
                font-size: 14px;
              }
            }
          }
        }
      }
    `}</style>
  );
}
