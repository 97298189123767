import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import {
  moveInArrayAction,
  removeFromArrayAction,
  updateAttributeAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsAddNestedContentElementThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import {
  MEDIA_TYPE_IMG,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_YOUTUBE,
} from "@/utils/constants";
import { getIdOrNewId } from "@/utils/util";
import React, { useState } from "react";
import CmsGeneralConfigFrame from "../cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import CmsMediaSelector from "../cmsMediaSelector/cmsMediaSelector";

interface CmsNestedContentGalleryProps {
  contentElement: any;
  cePosition: number;
}

export default function CmsNestedContentGallery(
  props: CmsNestedContentGalleryProps
) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const nestedElementAttributes = useAppSelector((state) => {
    return state.cmsEdit.data?.availableElements?.find(
      (element: any) => props.contentElement.__component === element.uid
    )?.nestedElement;
  });

  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const { showConfirmModal } = useConfirmModal(() => {
    dispatch(
      removeFromArrayAction({
        attributePath: `draftPage.content[${props.cePosition}].content`,
        position: deleteIndex,
      })
    );
  });

  return (
    <>
      {props.contentElement?.content?.map(
        (nestedElement: any, index: number, arr: Array<any>) => {
          return (
            <React.Fragment
              key={`nested-content-gallery-${props.cePosition}-${index}`}
            >
              <CmsGeneralConfigFrame
                initialFocus={index === arr.length - 1}
                title={tCms(nestedElementAttributes.uid)}
                key={getIdOrNewId(nestedElement, index)}
                frameOnHover
                position={index}
                hideAddCEButton={true}
                arrayLength={props.contentElement?.content?.length}
                moveUp={() =>
                  dispatch(
                    moveInArrayAction({
                      attributePath: `draftPage.content[${props.cePosition}].content[${index}]`,
                      direction: "up",
                    })
                  )
                }
                moveDown={() =>
                  dispatch(
                    moveInArrayAction({
                      attributePath: `draftPage.content[${props.cePosition}].content[${index}]`,
                      direction: "down",
                    })
                  )
                }
                onAddBtnClick={() =>
                  dispatch(
                    cmsAddNestedContentElementThunk(
                      nestedElementAttributes,
                      props.cePosition,
                      "content",
                      index + 1
                    )
                  )
                }
                onDelete={() => {
                  setDeleteIndex(index);
                  showConfirmModal({
                    title: tCms("nestedContent-deleteGallerySlideConfirmation"),
                    content: tCms(
                      "nestedContent-deleteGallerySlideConfirmationLong"
                    ),
                    acceptBtnText: tCms("delete"),
                    denyBtnText: tCms("cancel"),
                    xIsDeny: true,
                    icon: "triangle-exclamation-light",
                  });
                }}
              >
                <CmsMediaSelector
                  maxHeight={156}
                  mediaElement={nestedElement}
                  defaultValue={MEDIA_TYPE_IMG}
                  mediaOptions={[
                    MEDIA_TYPE_IMG,
                    MEDIA_TYPE_VIDEO,
                    MEDIA_TYPE_YOUTUBE,
                  ]}
                  resetAllAttributesOnMediaTypeChange
                  attributeNameImage="img"
                  attributeNameVideo="vid"
                  attributeNameVideoThumbnail="videoFileName"
                  attributeNameYoutubeUrl="youtubeUrl"
                  onChangeYoutube={(onChangeYoutubeValues) =>
                    dispatch(
                      updateAttributeAction({
                        attributePath: `draftPage.content[${props.cePosition}].content[${index}].youtubeUrl`,
                        value: onChangeYoutubeValues.ytUrl,
                      })
                    )
                  }
                  onChangeImage={(onChangeImageValues) =>
                    dispatch(
                      updateAttributeAction({
                        attributePath: `draftPage.content[${props.cePosition}].content[${index}].img`,
                        value: onChangeImageValues.img,
                      })
                    )
                  }
                  onChangeVideo={(onChangeVideoValues) => {
                    if (onChangeVideoValues.hasOwnProperty("video")) {
                      dispatch(
                        updateAttributeAction({
                          attributePath: `draftPage.content[${props.cePosition}].content[${index}].vid`,
                          value: onChangeVideoValues.video,
                        })
                      );
                    }
                    if (onChangeVideoValues.hasOwnProperty("videoTn")) {
                      dispatch(
                        updateAttributeAction({
                          attributePath: `draftPage.content[${props.cePosition}].content[${index}].vtn`,
                          value: onChangeVideoValues.videoTn,
                        })
                      );
                    }
                  }}
                />
              </CmsGeneralConfigFrame>
              <hr />
            </React.Fragment>
          );
        }
      )}
      <ModalInputWrapper
        label={tCms("addNewElement")}
        description={tCms("nestedContent-addContentElement", {
          contentElement: tCms("pb.gllrysld"),
        })}
      >
        <PbButton
          onClick={() =>
            dispatch(
              cmsAddNestedContentElementThunk(
                nestedElementAttributes,
                props.cePosition,
                "content"
              )
            )
          }
          startIcon={
            <PbIcon
              name="plus-light"
              width={15}
              height={15}
              className="svg-fill-white"
            />
          }
        >
          {tCms("add")}
        </PbButton>
      </ModalInputWrapper>
    </>
  );
}
