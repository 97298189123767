import PbButton from "@/components/input/pbButton/pbButton";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { hideNestedContentModal } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CmsNestedContentCarousel from "./cmsNestedContentCarousel";
import CmsNestedContentGallery from "./cmsNestedContentGallery";
import CmsNestedContentImageTicker from "./cmsNestedContentImageTicker";
import CmsNestedContentImageWithMarkers from "./cmsNestedContentImageWithMarkers";
import CmsNestedContentQuickLinks from "./cmsNestedContentQuickLinks";

interface CmsNestedContentModalProps {}

export default function CmsNestedContentModal(
  props: CmsNestedContentModalProps
) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const renderKey = useAppSelector((state) => state.cmsGeneral.renderKey);
  const open = useAppSelector(
    (state) => state.cmsGeneral.nestedContentModal.open
  );
  const position = useAppSelector(
    (state) => state.cmsGeneral.nestedContentModal.position
  );

  const currentElement = useAppSelector((state) => {
    if (position || position === 0) {
      return state.cmsEdit.data?.draftPage?.content[position];
    }
    return undefined;
  });

  const nestedContentComponents: any = {
    "pb.cgllry": CmsNestedContentGallery,
    "pb.cmgtckr": CmsNestedContentImageTicker,
    "pb.ccrsl": CmsNestedContentCarousel,
    "pb.cmgwthmrkrs": CmsNestedContentImageWithMarkers,
    "pb.cqcklnks": CmsNestedContentQuickLinks,
  };

  const NestedContentComponent =
    nestedContentComponents[currentElement?.__component];
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      className="cms-modal cms-nested-content-modal"
    >
      <DialogTitle className="cms-modal-title">
        {tCms("nestedContent-editContentElement", {
          contentElement:
            " - " + tCms("contentelement-" + currentElement?.__component),
        })}
      </DialogTitle>
      <IconButton
        className="modal-close"
        aria-label="close"
        onClick={() => {
          dispatch(hideNestedContentModal());
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent key={renderKey}>
        {NestedContentComponent ? (
          <NestedContentComponent
            contentElement={currentElement}
            cePosition={position}
          />
        ) : (
          <>{tCms("nestedContent-notFound")}</>
        )}
      </DialogContent>
      <DialogActions>
        <PbButton
          color="primary"
          onClick={() => {
            dispatch(hideNestedContentModal());
          }}
        >
          {tCms("confirm")}
        </PbButton>
      </DialogActions>
    </Dialog>
  );
}
