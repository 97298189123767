import CmsLinkChooser from "@/components/cms/cmsLinkChooser/cmsLinkChooser";
import PbButton from "@/components/input/pbButton/pbButton";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";

export interface CmsLinkChooserModalResult {
  url: string;
  rel: string;
  target: string;
  hrefLang: string;
  getParams: string;
  anchorName: string;
}

interface CmsLinkChooserModalProps {
  open: boolean;
  title: string;
  url: string;
  rel: string;
  target: string;
  hrefLang: string;
  getParams: string;
  anchorName: string;
  onConfirm: (result: CmsLinkChooserModalResult) => void;
  onCancel: () => void;
}

export default function CmsLinkChooserModal(props: CmsLinkChooserModalProps) {
  const tCms = useCmsTranslation();
  const [url, setUrl] = useState<string>(props.url);
  const [rel, setRel] = useState<string>(props.rel);
  const [target, setTarget] = useState<string>(props.target);
  const [hrefLang, setHrefLang] = useState<string>(props.hrefLang);
  const [getParams, setGetParams] = useState<string>(props.getParams);
  const [anchorName, setAnchorName] = useState<string>(props.anchorName);

  const resetStateAndClose = () => {
    setUrl(props.url);
    setRel(props.rel);
    setTarget(props.target || "_self");
    setHrefLang(props.hrefLang);
    setGetParams(props.getParams);
    setAnchorName(props.anchorName);
    props.onCancel();
  };

  return (
    <Dialog
      className="cms-modal cms-link-chooser-modal"
      open={props.open}
      fullWidth={true}
      scroll={"paper"}
      onClose={() => resetStateAndClose()}
    >
      <DialogTitle className="cms-modal-title">{props.title}</DialogTitle>
      <IconButton
        className="modal-close"
        aria-label="close"
        onClick={() => props.onCancel()}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <CmsLinkChooser
          url={url}
          onUrlChange={(newUrl) => {
            setUrl(newUrl);
          }}
          rel={rel}
          onRelChange={(newRel) => {
            setRel(newRel);
          }}
          target={target || "_self"}
          onTargetChange={(newTarget) => {
            setTarget(newTarget);
          }}
          hrefLang={hrefLang}
          onHrefLangChange={(newHrefLang) => {
            setHrefLang(newHrefLang);
          }}
          getParams={getParams}
          onGetParamsChange={(newGetParams) => {
            setGetParams(newGetParams);
          }}
          anchor={anchorName}
          onAnchorChange={(newAnchor) => {
            setAnchorName(newAnchor);
          }}
        ></CmsLinkChooser>
      </DialogContent>
      <DialogActions>
        <PbButton
          color="secondary"
          variant="outlined"
          autoFocus
          onClick={() => resetStateAndClose()}
        >
          {tCms("cancel")}
        </PbButton>
        <PbButton
          color="primary"
          onClick={() =>
            props.onConfirm({
              url,
              rel,
              target,
              hrefLang,
              getParams,
              anchorName,
            })
          }
        >
          {tCms("save")}
        </PbButton>
      </DialogActions>
    </Dialog>
  );
}
