import { PbPage } from "@/types/content-elements";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import Head from "next/head";
import { useRouter } from "next/router";

export interface LanguageHeadTagsDynamicProps {
  locale: string;
  urlPrefix: string;
  url: string;
  localizations: Array<PbPage>;
  customCanonicalTag: string;
}

/**
 * This component is used for page routes like index.ts, [...url].ts
 *
 * This component injects all localization (translation) <link> tags to the HTML head
 *
 * more information:
 * https://developers.google.com/search/docs/advanced/crawling/localized-versions?hl=de
 *
 * if the page is not translated the default page is displayed then this component
 * adds a canonical link to the head
 */
const LanguageHeadTagsDynamic = (props: LanguageHeadTagsDynamicProps) => {
  const router = useRouter();
  const isNotTranslated = router.locale !== props.locale;

  const rootUrl = process.env.NEXT_PUBLIC_NEXTJS_URL;

  /**
   * the page param can be props or the object from the localizations
   * array containing {id: , locale: }
   *
   * @param {*} isDefaultLocale
   * @param {*} page
   * @returns
   */
  const buildHrefUrl = (isDefaultLocale: boolean, page: Partial<PbPage>) => {
    if (isDefaultLocale) {
      // domain.com/[optionalPrefix/]url
      return `${rootUrl}/${urlPrefix()}${props.url}`;
    } else {
      // domain.com/en/[optionalPrefix/]url
      return `${rootUrl}/${page.locale}/${urlPrefix()}${props.url}`;
    }
  };

  const urlPrefix = () => {
    if (props.urlPrefix) {
      const urlPrefixNoBeginningSlash =
        props.urlPrefix && props.urlPrefix.startsWith("/")
          ? props.urlPrefix.substring(1)
          : props.urlPrefix;
      return `${urlPrefixNoBeginningSlash}/`;
    }
    return "";
  };

  return (
    <>
      {isNotTranslated && !props.customCanonicalTag ? (
        <Head>
          <link rel="canonical" href={buildHrefUrl(true, props)} />
        </Head>
      ) : (
        <Head>
          {/* current page */}
          {props.locale ? (
            <link
              rel="alternate"
              hrefLang={isLocaleDefaultLocale(props.locale) ? "x-default" : props.locale}
              href={buildHrefUrl(isLocaleDefaultLocale(props.locale), props)}
            />
          ) : null}
          {/* translations of the page (localizations array) */}
          {props.localizations &&
            props.localizations.map((translation: PbPage, index: number) => {
              return (
                <link
                  key={index}
                  rel="alternate"
                  hrefLang={
                    isLocaleDefaultLocale(translation.locale) ? "x-default" : translation.locale
                  }
                  href={buildHrefUrl(isLocaleDefaultLocale(translation.locale), translation)}
                />
              );
            })}
        </Head>
      )}
    </>
  );
};

export default LanguageHeadTagsDynamic;
