export interface CmsPageWarningProps {
  children: React.ReactNode;
}

/**
 * CmsPageWarning
 * Displays HTML-Text as Warning.
 *
 * @param {React.ReactNode} children
 * @returns
 */
const CmsPageWarning = (props: CmsPageWarningProps) => {
  return (
    <>
      <div className="cms-page-warning">{props.children}</div>
      <style jsx>{`
        .cms-page-warning {
          width: 80%;
          max-width: fit-content;
          padding: 1.5rem;
          border: 2px dashed #e0284f;
          border-radius: 8px;

          text-align: center;

          margin-left: auto;
          margin-right: auto;

          margin-top: 1.5rem;
          margin-bottom: 1.5rem;

          color: black;
          background-color: white;

          /* Hide if no children exist. */
          &:empty {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default CmsPageWarning;
