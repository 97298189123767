import { determineStoreValueId } from "@/services/ceSettings/ceSettingsService";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { STORE_BACKGROUND_IMAGE_CLASS_PREFIX } from "@/utils/constants";

import { globalConfig } from "@/services/globalConfig/globalConfigService";

export default function useStrBgImageOnPage(
  backgroundImageStoreSetting?: StoreSetting
) {
  const backgroundImageId = determineStoreValueId(
    backgroundImageStoreSetting,
    globalConfig?.layout.bgImages
  );

  const backgroundImageClassName = backgroundImageId
    ? `${STORE_BACKGROUND_IMAGE_CLASS_PREFIX}${backgroundImageId}`
    : "";

  return { backgroundImageClassName };
}
