import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { showAddContentModal } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { Container } from "react-bootstrap";
import CmsAddContentButtonStyle from "./cmsAddContentButtonStyle";

export interface CmsAddContentButtonProps {}

/**
 * Component that on click opens a modal to select one of the given availableElements
 */
const CmsAddContentButton = (props: CmsAddContentButtonProps) => {
  const tCms = useCmsTranslation();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const availableElements = useAppSelector(
    (state) => state.cmsEdit.data?.availableElements
  );
  const dispatch = useAppDispatch();

  return (
    <>
      {editView && availableElements?.length > 0 ? (
        <>
          <Container fluid>
            <button
              className="add-content-zone"
              onClick={() => dispatch(showAddContentModal(null))}
            >
              <span className="btn-text">{tCms("addNewElement")}</span>
              <span className="btn-icon">
                <PbIcon name="plus-light" width={50} height={50} />
              </span>
            </button>
          </Container>
          <CmsAddContentButtonStyle />
        </>
      ) : null}
    </>
  );
};

export default CmsAddContentButton;
