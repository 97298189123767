export default function CmsConfigModalStyle() {
  return (
    <style jsx global>{`
      .cms-config-modal {
        &.side-menu {
          position: fixed;
          top: 0;
          left: 0;
          right: unset;
          bottom: unset;
          width: 375px;

          .cms-modal-title {
            padding: 15px;
            word-break: break-word;
          }

          .modal-close {
            top: 15px;
            right: 15px;
          }

          .cms-tooltip {
            padding: 0;
            margin-left: 8px;
          }

          .MuiTabPanel-root {
            padding: 15px;
          }

          .MuiBackdrop-root {
            background-color: transparent;
          }

          .MuiDialog-container {
            justify-content: flex-start;
          }

          .MuiPaper-root.MuiDialog-paper {
            margin: 0;
            height: 100vh;
            width: 100%;
          }
        }
      }
    `}</style>
  );
}
