import { determineStoreValueId } from "@/services/ceSettings/ceSettingsService";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { STORE_FONT_CLASS_PREFIX } from "@/utils/constants";

export default function useStrFont(
  fontStoreSetting?: StoreSetting,
  ceSettings?: StoreSetting
) {
  const fontId = determineStoreValueId(fontStoreSetting, ceSettings);

  const fontClassName = fontId ? `${STORE_FONT_CLASS_PREFIX}${fontId}` : "";

  return { fontClassName };
}
