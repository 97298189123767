import { globalConfig } from "@/services/globalConfig/globalConfigService";
import {
  lowerBreakpointDesktopPx,
  lowerBreakpointTablet,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhd,
  lowerBreakpointWqhdPx,
  spaceInPercent,
} from "@/utils/util";

export interface ContentElementSpaceProps {
  spaceX: number;
  spaceY: number;
  containerClassName: string;
}

const SCALINGFACTOR_DEFAULT_MOBILE = 0.25;
const SCALINGFACTOR_DEFAULT_TABLET = 0.5;
const SCALINGFACTOR_DEFAULT_WQHD = 1.25;

export default function ContentElementSpace({
  spaceX,
  spaceY,
  containerClassName,
}: ContentElementSpaceProps) {
  const validateSetting = (setting: any) => {
    if (setting !== null && setting !== "") {
      return setting;
    }
    return null;
  };

  const scalingfactorSpaceXMobile =
    validateSetting(globalConfig?.layout.scalingfactorSpaceX.mobile) ??
    SCALINGFACTOR_DEFAULT_MOBILE;

  const scalingfactorSpaceXTablet =
    validateSetting(globalConfig?.layout.scalingfactorSpaceX.tablet) ??
    SCALINGFACTOR_DEFAULT_TABLET;

  const scalingfactorSpaceXWqhd =
    validateSetting(globalConfig?.layout.scalingfactorSpaceX.wqhd) ??
    SCALINGFACTOR_DEFAULT_WQHD;

  const scalingfactorSpaceYMobile =
    validateSetting(globalConfig?.layout.scalingfactorSpaceY.mobile) ??
    SCALINGFACTOR_DEFAULT_MOBILE;

  const scalingfactorSpaceYTablet =
    validateSetting(globalConfig?.layout.scalingfactorSpaceY.tablet) ??
    SCALINGFACTOR_DEFAULT_TABLET;

  const scalingfactorSpaceYWqhd =
    validateSetting(globalConfig?.layout.scalingfactorSpaceY.wqhd) ??
    SCALINGFACTOR_DEFAULT_WQHD;

  const spaceXrow = spaceX - 8 < 0 ? 0 : spaceX - 8; // 8, 8+ or 0
  const spaceXcontainer = spaceX !== 0 ? (8 - spaceX > 0 ? 8 - spaceX : 8) : 0; // 0, 1-7 or 8

  return (
    <style jsx global>{`
      :global(.${containerClassName}) {
        /* Container Mobile */
        padding-left: ${spaceXcontainer *
        scalingfactorSpaceXMobile}px !important;
        padding-right: ${spaceXcontainer *
        scalingfactorSpaceXMobile}px !important;

        /* Container Tablet */
        @media (min-width: ${lowerBreakpointTabletPx}) {
          padding-left: ${spaceXcontainer *
          scalingfactorSpaceXTablet}px !important;
          padding-right: ${spaceXcontainer *
          scalingfactorSpaceXTablet}px !important;
        }

        /* Container Desktop */
        @media (min-width: ${lowerBreakpointDesktopPx}) {
          padding-left: ${spaceXcontainer}px !important;
          padding-right: ${spaceXcontainer}px !important;
        }

        /* Container 2k Desktop */
        @media (min-width: ${lowerBreakpointWqhdPx}) {
          padding-left: ${spaceXcontainer *
          scalingfactorSpaceXWqhd}px !important;
          padding-right: ${spaceXcontainer *
          scalingfactorSpaceXWqhd}px !important;
        }
        & > div {
          padding-top: ${spaceY * scalingfactorSpaceYMobile}px !important;
          padding-bottom: ${spaceY * scalingfactorSpaceYMobile}px !important;
          padding-left: ${spaceXrow * scalingfactorSpaceXMobile}px !important;
          padding-right: ${spaceXrow * scalingfactorSpaceXMobile}px !important;

          @media (min-width: ${lowerBreakpointTabletPx}) {
            padding-top: ${spaceY * scalingfactorSpaceYTablet}px !important;
            padding-bottom: ${spaceY * scalingfactorSpaceYTablet}px !important;
            padding-left: ${spaceInPercent(
              spaceXrow,
              lowerBreakpointTablet,
              scalingfactorSpaceXTablet
            )} !important;
            padding-right: ${spaceInPercent(
              spaceXrow,
              lowerBreakpointTablet,
              scalingfactorSpaceXTablet
            )} !important;
          }
          @media (min-width: ${lowerBreakpointDesktopPx}) {
            padding-top: ${spaceY}px !important;
            padding-bottom: ${spaceY}px !important;
            padding-left: ${spaceInPercent(spaceXrow, 1720, 1)} !important;
            padding-right: ${spaceInPercent(spaceXrow, 1720, 1)} !important;
          }

          /* FullHD-Dimension */
          /* Note: Only (!) between 1720px and 2120px the exact spaceX/Y-Px-Value is used.
            All other media-queries use to percent transformed values. */
          @media (min-width: 1720px) {
            padding-top: ${spaceY}px !important;
            padding-bottom: ${spaceY}px !important;
            padding-left: ${spaceXrow}px !important;
            padding-right: ${spaceXrow}px !important;
          }

          /* above FullHD-Dimension */
          @media (min-width: 2120px) {
            padding-top: ${spaceY}px !important;
            padding-bottom: ${spaceY}px !important;
            padding-left: ${spaceInPercent(
              spaceXrow,
              lowerBreakpointWqhd || 0,
              1
            )} !important;
            padding-right: ${spaceInPercent(
              spaceXrow,
              lowerBreakpointWqhd || 0,
              1
            )} !important;
          }

          /* WQHD */
          @media (min-width: ${lowerBreakpointWqhd}) {
            padding-top: ${spaceY * scalingfactorSpaceYWqhd}px !important;
            padding-bottom: ${spaceY * scalingfactorSpaceYWqhd}px !important;
            padding-left: ${spaceInPercent(
              spaceXrow,
              lowerBreakpointWqhd,
              scalingfactorSpaceXWqhd
            )} !important;
            padding-right: ${spaceInPercent(
              spaceXrow,
              lowerBreakpointWqhd,
              scalingfactorSpaceXWqhd
            )} !important;
          }
        }
      }
    `}</style>
  );
}
