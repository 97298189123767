export default function HtmlEditorModalGlobalStyle() {
  return (
    <style jsx global>{`
      .cms-html-editor-modal {
        .MuiOutlinedInput-root {
          .MuiInputBase-inputMultiline {
            height: 50vh !important;
            overflow-y: scroll !important;
          }
        }
      }
    `}</style>
  );
}
