import {
  ceSettingById,
  CFG_CONTENT_ELEMENT_SETTING_NAME,
  firstCeSettingByStoreType,
  valueFromStoreSetting,
} from "@/services/ceSettings/ceSettingsService";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import {
  refreshRenderKey,
  setCurrentContentElementSettingAction,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch } from "@/store/store";
import { StoreSetting } from "@/types/ceSettings/ceSettings";

export default function useContentElementStore() {
  const dispatch = useAppDispatch();

  const setCurrentContentElementSetting = (
    contentElementSetting: StoreSetting
  ) => {
    if (!contentElementSetting) {
      return;
    }
    const id = valueFromStoreSetting(contentElementSetting);
    if (id) {
      dispatch(setCurrentContentElementSettingAction(ceSettingById(id)));
    } else {
      dispatch(
        setCurrentContentElementSettingAction(
          firstCeSettingByStoreType(contentElementSetting.storeType)
        )
      );
    }
  };

  const resetContentElementSetting = (
    configModalEntryName: string,
    element: any,
    positionPath: string | null
  ) => {
    if (positionPath === null || !element) {
      return;
    }
    Object.entries(element)
      .filter(
        ([key, _]) =>
          key.startsWith("cfgStr") && key !== CFG_CONTENT_ELEMENT_SETTING_NAME
      )
      .forEach(([key, value]) => {
        if (key === configModalEntryName) {
          dispatch(
            updateAttributeAction({
              attributePath: `draftPage.${positionPath}.${key}`,
              value: {
                ...(value as StoreSetting),
                values: [],
              } as StoreSetting,
            })
          );
        }
      });
    dispatch(refreshRenderKey());
  };

  return {
    setCurrentContentElementSetting,
    resetContentElementSetting,
  };
}
