export default function CmsContentButtonGlobalStyle() {
  return (
    <style jsx global>
      {`
        .cms-content-button {
          position: relative;
          width: fit-content;

          .opener-btn {
            opacity: 1;
            width: fit-content;
            padding: 0;
            background: transparent;
            border: none;
          }
          .opener-btn:hover {
            cursor: pointer;
            opacity: 0.7;
          }

          .cms-button-input {
            border-radius: var(--pb-rounded-input-border-radius);
            border: 1px solid #ccc !important;
            background-color: transparent !important;
            color: inherit;
            outline: none !important;
            min-height: 30px;
            text-align: center;
            text-overflow: ellipsis;
            text-transform: inherit;
            font-size: inherit;
            font-weight: inherit;
            letter-spacing: inherit;
            font-family: inherit;
          }

          // makes input grow with text
          // see https://codepen.io/shshaw/pen/bGNJJBE
          .input-sizer {
            display: inline-grid;
            vertical-align: top;
            align-items: center;
            position: relative;

            &::after,
            input {
              width: auto;
              min-width: 1em;
              grid-area: 1 / 2;
              font: inherit;
              padding: 0.25em;
              margin: 0;
              resize: none;
              background: none;
              appearance: none;
              border: none;
            }

            &::after {
              content: attr(data-value) " ";
              visibility: hidden;
              white-space: pre-wrap;
              overflow: hidden;
              text-wrap: nowrap;
            }

            &:focus-within {
              input:focus {
                outline: none;
              }
            }
          }
        }
      `}
    </style>
  );
}
