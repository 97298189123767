import { PbContentHeadingSettings } from "@/components/content/pbContentHeading/pbContentHeading";
import {
  ContentElementHeadlineStoreSetting,
  StoreSetting,
} from "@/types/ceSettings/ceSettings";
import { HeadlineHeading } from "@/types/config/enums";
import clsx from "clsx";
import useStrColor from "./useStrColor";
import useStrFont from "./useStrFont";
import useStrTypography from "./useStrTypography";

export default function useCesStrHeadline(settings?: {
  typography?: StoreSetting;
  font?: StoreSetting;
  fontColor?: StoreSetting;
  ceSettingHeadline?: string;
  cfgHeadlineType?: HeadlineHeading;
  cfgHeadlineStyle?: HeadlineHeading;
  ceSettings?: ContentElementHeadlineStoreSetting;
  additionalClasses?: string;
}): PbContentHeadingSettings {
  const { typographyClassName } = useStrTypography(
    settings?.typography,
    settings?.ceSettings?.typographys
  );

  const { fontClassName } = useStrFont(
    settings?.font,
    settings?.ceSettings?.fonts
  );

  const { colorClassName } = useStrColor(
    settings?.fontColor,
    settings?.ceSettings?.fontColors
  );

  const headlineheading =
    settings?.cfgHeadlineType && settings?.cfgHeadlineType !== "default"
      ? (settings?.cfgHeadlineType as HeadlineHeading)
      : ((settings?.ceSettingHeadline || "default") as HeadlineHeading);

  return {
    headlineClasses: clsx(
      typographyClassName,
      fontClassName,
      colorClassName,
      settings?.cfgHeadlineStyle &&
        settings.cfgHeadlineStyle !== "default" &&
        settings.cfgHeadlineStyle,
      settings?.additionalClasses
    ),
    HeadingTag:
      headlineheading && headlineheading !== "default"
        ? headlineheading === "paragraph"
          ? "p"
          : headlineheading
        : "h1",
  };
}
