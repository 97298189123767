import { CmsConfigRendererProps } from "@/components/cms/cmsConfigModal/cmsConfigRenderer";
import useConfigModalTabs from "@/hooks/useConfigModalTabs";
import GenericConfigTabs from "./genericConfigTabs";

interface CmsGenericConfigProps extends CmsConfigRendererProps {}

export default function CmsGenericConfig(props: CmsGenericConfigProps) {
  const { configModalTabs } = useConfigModalTabs(
    props.attributes,
    props.component
  );

  return (
    <GenericConfigTabs
      configModalTabs={configModalTabs}
      onChange={props.onChange}
    ></GenericConfigTabs>
  );
}
