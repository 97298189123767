import { HtmlContentElementProps } from "./htmlContentElement";

interface HtmlContentElementStyleScopedProps extends HtmlContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 *
 * @param props
 * @returns
 */
export default function HtmlContentElementStyleScoped(
  props: HtmlContentElementStyleScopedProps
) {
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
      }
    `}</style>
  );
}
