const CmsAddContentButtonStyle = () => {
  return (
    <style jsx global>
      {`
        .add-content-zone {
          font-size: 2.4rem;
          font-weight: 400;
          margin: 4rem;
          width: calc(100% - 8rem);
          height: 150px;
          background-color: unset;
          border: none;
          .btn-icon {
            display: none;
            .plus-icon {
              fill: var(--pb-cms-color);
            }
          }
          &:hover {
            .btn-icon {
              display: block;
            }
            .btn-text {
              display: none;
            }
          }
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23198FD9FF' stroke-width='8' stroke-dasharray='20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        }
      `}
    </style>
  );
};
export default CmsAddContentButtonStyle;
