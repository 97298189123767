export default function useConfigModalTabExtension(children: any) {
  const renderTabExtensionChildrenAtPosition = (
    tabName: string,
    position: number,
    afterElement?: boolean
  ): React.ReactNode | null => {
    if (!children) {
      return null;
    }

    if (!Array.isArray(children)) {
      children = [children];
    }

    for (const child of children) {
      if (
        child.props.tabName === tabName &&
        child.props.index === position &&
        !!afterElement === !!child.props.afterElement
      ) {
        return child;
      }
    }
    return null;
  };

  const getAllTabExtensionChildrenWithoutIndex = (
    tabName: string
  ): Array<React.ReactNode> => {
    let tabChildren: Array<React.ReactNode> = [];

    if (!children) {
      return tabChildren;
    }

    if (!Array.isArray(children)) {
      children = [children];
    }

    for (const child of children) {
      if (
        child.props.tabName === tabName &&
        typeof child.props.index === "undefined"
      ) {
        tabChildren.push(child);
      }
    }
    return tabChildren;
  };

  const getAllTabExtensionChildren = (
    tabName: string
  ): Array<React.ReactNode> => {
    let tabChildren: Array<React.ReactNode> = [];

    if (!children) {
      return tabChildren;
    }

    if (!Array.isArray(children)) {
      children = [children];
    }

    for (const child of children) {
      if (child.props.tabName === tabName) {
        tabChildren.push(child);
      }
    }
    return tabChildren;
  };

  return {
    renderTabExtensionChildrenAtPosition,
    getAllTabExtensionChildren,
    getAllTabExtensionChildrenWithoutIndex,
  };
}
