import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { StrapiUploadFile } from "@/types/strapi";
import {
  MAX_VIDEO_SIZE,
  MEDIA_TYPE_IMG,
  MEDIA_TYPE_SVG,
  MEDIA_TYPE_VIDEO,
} from "@/utils/constants";
import { useState } from "react";
import CmsImage from "../cmsImage/cmsImage";
import CmsMediaManagerSelector from "../cmsMediaManager/cmsMediaManagerSelector";

interface CmsMediaSelectorVideoProps {
  video: StrapiUploadFile | null;
  videoTn: StrapiUploadFile | null;
  onChange: (onChangeVideoValues: OnChangeVideoValues) => void;
}

export interface OnChangeVideoValues {
  video?: StrapiUploadFile | null;
  videoTn?: StrapiUploadFile | null;
}

export default function CmsMediaSelectorVideo(
  props: CmsMediaSelectorVideoProps
) {
  const tCms = useCmsTranslation();
  const [file, setFile] = useState<StrapiUploadFile | undefined | null>(
    props.videoTn
  );

  return (
    <>
      <ModalInputWrapper
        label={tCms("video")}
        description={tCms("mediaSelector-pleaseUploadVideo", {
          maxVideoSize: MAX_VIDEO_SIZE / 1024 / 1024 + "",
        })}
      >
        <CmsMediaManagerSelector
          mimeTypes={[MEDIA_TYPE_VIDEO]}
          onConfirm={(file) => {
            props.onChange && props.onChange({ video: file });
          }}
        >
          <PbButton fullWidth className="mb-2">
            {tCms("file-chooseFile")}
          </PbButton>
        </CmsMediaManagerSelector>
        {props.video ? (
          <>
            <PbButton
              title={tCms("remove")}
              onClick={() => {
                props.onChange && props.onChange({ video: null });
              }}
              startIcon={
                <PbIcon
                  name="trash-light"
                  width={25}
                  height={25}
                  className="svg-fill-white"
                />
              }
            />{" "}
            {props.video.name}
          </>
        ) : null}
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("thumbnail")}
        description={tCms("mediaSelector-selectVideoThumbnail")}
      >
        <CmsMediaManagerSelector
          mimeTypes={[MEDIA_TYPE_IMG, MEDIA_TYPE_SVG]}
          onConfirm={(file) => {
            setFile(file);
            props.onChange &&
              props.onChange({
                videoTn: file,
              });
          }}
        >
          {file && (
            <CmsImage
              image={file}
              format="thumbnail"
              className="pb-2"
            ></CmsImage>
          )}
        </CmsMediaManagerSelector>
        {props.videoTn ? (
          <div>
            <PbButton
              title={tCms("remove")}
              onClick={() => {
                setFile(null);
                props.onChange && props.onChange({ videoTn: null });
              }}
              startIcon={
                <PbIcon
                  name="trash-light"
                  width={25}
                  height={25}
                  className="svg-fill-white"
                />
              }
            />{" "}
            {props.videoTn?.name}
          </div>
        ) : null}
      </ModalInputWrapper>
    </>
  );
}
