export interface GenericConfigTabExtenderProps {
  children: React.ReactNode;
  tabName: string;
  index?: number;
  afterElement?: boolean;
}

export default function GenericConfigTabExtender(
  props: GenericConfigTabExtenderProps
) {
  return <>{props.children}</>;
}
