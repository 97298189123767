import { useAppSelector } from "@/store/store";

export default function useConfigModalCurrentElement() {
  const position = useAppSelector(
    (state) => state.cmsGeneral.configModal.position
  );
  const nestedPosition = useAppSelector(
    (state) => state.cmsGeneral.configModal.nestedPosition
  );

  const availableElements = useAppSelector(
    (state) => state.cmsEdit.data?.availableElements
  );

  const currentElement = useAppSelector((state) => {
    const draftPage = state.cmsEdit.data?.draftPage;
    if (!draftPage) {
      return undefined;
    }

    if (position || position === 0) {
      if (nestedPosition || nestedPosition === 0) {
        return {
          ...draftPage.content[position].content[nestedPosition],
          ...availableElements.find(
            (element: any) =>
              draftPage.content[position].__component === element.uid
          )?.nestedElement,
        };
      }
      return { ...draftPage.content[position], positionCE: position };
    }
    return undefined;
  });

  const isNestedElement =
    (position || position === 0) && (nestedPosition || nestedPosition === 0);

  const attributeBasePath = isNestedElement
    ? `content[${position}].content[${nestedPosition}]`
    : `content[${position}]`;

  const parentCE = useAppSelector((state) => {
    if (position !== null) {
      const parent = {
        ...state.cmsEdit.data.draftPage.content[position],
        positionCE: position,
      };

      if (parent.__component === currentElement.uid) {
        return null;
      }

      if (!parent.id) {
        return null;
      }

      return parent;
    } else {
      return null;
    }
  });

  return {
    isNestedElement,
    attributeBasePath,
    currentElement,
    position,
    nestedPosition,
    parentCE,
  };
}
