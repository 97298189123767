import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCesStrHeadline from "@/hooks/useCesStrHeadline";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import useStrButton from "@/hooks/useStrButton";
import {
  CFG_CONTENT_ELEMENT_SETTING_NAME,
  ceSettingById,
  valueFromStoreSetting,
} from "@/services/ceSettings/ceSettingsService";
import {
  moveInArrayAction,
  removeFromArrayAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsAddNestedContentElementThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementQuickLinksStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEQuickLinks } from "@/types/content-elements";
import { getIdOrNewId } from "@/utils/util";
import React, { useState } from "react";
import CmsContentButton from "../cmsContent/cmsContentButton/cmsContentButton";
import CmsGeneralConfigFrame from "../cmsGeneralConfigFrame/cmsGeneralConfigFrame";

interface CmsNestedContentQuickLinksProps {
  contentElement: CEQuickLinks;
  cePosition: number;
}

export default function CmsNestedContentQuickLinks(
  props: CmsNestedContentQuickLinksProps
) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();

  const currentElement = useAppSelector((state) => {
    if (props.cePosition || props.cePosition === 0) {
      return state.cmsEdit.data?.draftPage?.content[props.cePosition];
    }
    return undefined;
  });
  const contentElementSettingsId = valueFromStoreSetting(
    currentElement[CFG_CONTENT_ELEMENT_SETTING_NAME]
  );

  const ceSettings = ceSettingById(contentElementSettingsId)
    ?.setting! as ContentElementQuickLinksStoreSetting;

  const headlineSetting = useCesStrHeadline({
    typography: props.contentElement.cfgStrInnerHeadlineTypography,
    font: props.contentElement.cfgStrInnerHeadlineFont,
    fontColor: props.contentElement.cfgStrInnerHeadlineFontColor,
  });

  const { buttonClassName } = useStrButton(
    props.contentElement.cfgStrInnerButtonButton,
    ceSettings?.button
  );

  const nestedElementAttributes = useAppSelector((state) => {
    return state.cmsEdit.data?.availableElements?.find(
      (element: any) => props.contentElement.__component === element.uid
    )?.nestedElement;
  });

  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const { showConfirmModal } = useConfirmModal(() => {
    dispatch(
      removeFromArrayAction({
        attributePath: `draftPage.content[${props.cePosition}].content`,
        position: deleteIndex,
      })
    );
  });

  return (
    <>
      {props.contentElement?.content?.map(
        (nestedElement: any, index: number, arr: Array<any>) => {
          return (
            <React.Fragment
              key={`quicklinks-${props.cePosition}-element-${index}`}
            >
              <CmsGeneralConfigFrame
                initialFocus={index === arr.length - 1}
                title={tCms(nestedElementAttributes.uid)}
                key={getIdOrNewId(nestedElement, index)}
                frameOnHover
                position={index}
                arrayLength={props.contentElement?.content?.length}
                moveUp={() =>
                  dispatch(
                    moveInArrayAction({
                      attributePath: `draftPage.content[${props.cePosition}].content[${index}]`,
                      direction: "up",
                    })
                  )
                }
                moveDown={() =>
                  dispatch(
                    moveInArrayAction({
                      attributePath: `draftPage.content[${props.cePosition}].content[${index}]`,
                      direction: "down",
                    })
                  )
                }
                hideAddCEButton
                onAddBtnClick={() =>
                  dispatch(
                    cmsAddNestedContentElementThunk(
                      nestedElementAttributes,
                      props.cePosition,
                      "content",
                      index + 1
                    )
                  )
                }
                onDelete={() => {
                  setDeleteIndex(index);
                  showConfirmModal({
                    title: tCms(
                      "nestedContent-deleteContentElementConfirmation",
                      {
                        contentElement: tCms(nestedElementAttributes.uid),
                      }
                    ),
                    content: tCms(
                      "nestedContent-deleteContentElementConfirmationLong",
                      {
                        contentElement: tCms(nestedElementAttributes.uid),
                      }
                    ),
                    acceptBtnText: tCms("delete"),
                    denyBtnText: tCms("cancel"),
                    xIsDeny: true,
                    icon: "triangle-exclamation-light",
                  });
                }}
              >
                <ModalInputWrapper
                  label={tCms("button")}
                  description={tCms("buttonDescription")}
                >
                  <CmsContentButton
                    cmsFieldGetParams={`content[${index}].btnGetParams`}
                    cmsFieldHrefLang={`content[${index}].btnHrefLang`}
                    cmsFieldRel={`content[${index}].btnRel`}
                    cmsFieldTarget={`content[${index}].btnTarget`}
                    cmsFieldText={`content[${index}].btnText`}
                    cmsFieldUrl={`content[${index}].btnUrl`}
                    cmsFieldAnchorName={`content[${index}].btnAnchorName`}
                    cmsPopoverOpenerId=""
                    cmsPosition={props.cePosition}
                    getParams={nestedElement.btnGetParams}
                    hrefLang={nestedElement.btnHrefLang}
                    popoverPlacement="top"
                    rel={nestedElement.btnRel}
                    target={nestedElement.btnTarget}
                    text={nestedElement.btnText}
                    url={nestedElement.btnUrl}
                    anchorName={nestedElement.btnAnchorName}
                    className={buttonClassName}
                  />
                </ModalInputWrapper>
              </CmsGeneralConfigFrame>
              <hr />
            </React.Fragment>
          );
        }
      )}
      <ModalInputWrapper
        label={tCms("addNewElement")}
        description={tCms("nestedContent-addContentElement", {
          contentElement: tCms("pb.qcklnk"),
        })}
      >
        <PbButton
          onClick={() =>
            dispatch(
              cmsAddNestedContentElementThunk(
                nestedElementAttributes,
                props.cePosition,
                "content"
              )
            )
          }
          startIcon={
            <PbIcon
              name="plus-light"
              width={15}
              height={15}
              className="svg-fill-white"
            />
          }
        >
          {tCms("add")}
        </PbButton>
      </ModalInputWrapper>
    </>
  );
}
