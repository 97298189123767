import { HTML_CE_NAME, HtmlContentElementProps } from "./htmlContentElement";

export default function HtmlContentElementStyleGlobal(
  props: HtmlContentElementProps
) {
  return (
    <style jsx global>{`
      :global(.${HTML_CE_NAME}-wrapper) {
      }
    `}</style>
  );
}
