import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import {
  moveInArrayAction,
  removeFromArrayAction,
  updateAttributeAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsAddNestedContentElementThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { MEDIA_TYPE_IMG } from "@/utils/constants";
import { getIdOrNewId } from "@/utils/util";
import React, { useState } from "react";
import CmsGeneralConfigFrame from "../cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import CmsLinkChooser from "../cmsLinkChooser/cmsLinkChooser";
import CmsMediaSelector from "../cmsMediaSelector/cmsMediaSelector";

interface CmsNestedContentImageTickerProps {
  contentElement: any;
  cePosition: number;
}

export default function CmsNestedContentImageTicker(
  props: CmsNestedContentImageTickerProps
) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const nestedElementAttributes = useAppSelector((state) => {
    return state.cmsEdit.data?.availableElements?.find(
      (element: any) => props.contentElement.__component === element.uid
    )?.nestedElement;
  });

  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const { showConfirmModal } = useConfirmModal(() => {
    dispatch(
      removeFromArrayAction({
        attributePath: `draftPage.content[${props.cePosition}].content`,
        position: deleteIndex,
      })
    );
  });

  const updateNestedContentElement = (
    key: string,
    value: any,
    index: number
  ) => {
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content[${props.cePosition}].content[${index}][${key}]`,
        value: value,
      })
    );
  };

  return (
    <>
      {props.contentElement?.content?.map(
        (nestedElement: any, index: number, arr: Array<any>) => {
          return (
            <React.Fragment key={`image-ticker-${props.cePosition}-${index}`}>
              <CmsGeneralConfigFrame
                initialFocus={index === arr.length - 1}
                title={tCms(nestedElementAttributes.uid)}
                key={getIdOrNewId(nestedElement, index)}
                frameOnHover
                position={index}
                hideAddCEButton={true}
                arrayLength={props.contentElement?.content?.length}
                moveUp={() =>
                  dispatch(
                    moveInArrayAction({
                      attributePath: `draftPage.content[${props.cePosition}].content[${index}]`,
                      direction: "up",
                    })
                  )
                }
                moveDown={() =>
                  dispatch(
                    moveInArrayAction({
                      attributePath: `draftPage.content[${props.cePosition}].content[${index}]`,
                      direction: "down",
                    })
                  )
                }
                onAddBtnClick={() =>
                  dispatch(
                    cmsAddNestedContentElementThunk(
                      nestedElementAttributes,
                      props.cePosition,
                      "content",
                      index + 1
                    )
                  )
                }
                onDelete={() => {
                  setDeleteIndex(index);
                  showConfirmModal({
                    title: tCms("nestedContent-deleteElementConfirmation"),
                    content: tCms(
                      "nestedContent-deleteElementConfirmationLong"
                    ),
                    acceptBtnText: tCms("delete"),
                    denyBtnText: tCms("cancel"),
                    xIsDeny: true,
                    icon: "triangle-exclamation-light",
                  });
                }}
              >
                <div className="cms-modal-input-wrapper-container pb-5 pt-5">
                  <CmsMediaSelector
                    maxHeight={156}
                    mediaElement={nestedElement}
                    defaultValue={MEDIA_TYPE_IMG}
                    mediaOptions={[MEDIA_TYPE_IMG]}
                    resetAllAttributesOnMediaTypeChange
                    attributeNameImage="img"
                    onChangeImage={(onChangeImageValues) =>
                      updateNestedContentElement(
                        "img",
                        onChangeImageValues.img,
                        index
                      )
                    }
                  />
                  <ModalInputWrapper
                    label={tCms("dynamicList-link")}
                    description={tCms(
                      "dynamicListModalItem-linkChooserDescription"
                    )}
                  >
                    <CmsLinkChooser
                      label={tCms("linkUrl")}
                      url={nestedElement.imageUrl}
                      rel={nestedElement.linkRel}
                      target={nestedElement.linkTarget}
                      getParams={nestedElement.linkGetParams}
                      hrefLang={nestedElement.linkHrefLang}
                      anchor={nestedElement.linkAnchor}
                      onUrlChange={(url) =>
                        updateNestedContentElement("imageUrl", url, index)
                      }
                      onGetParamsChange={(getParams) =>
                        updateNestedContentElement(
                          "linkGetParams",
                          getParams,
                          index
                        )
                      }
                      onRelChange={(rel) =>
                        updateNestedContentElement("linkRel", rel, index)
                      }
                      onHrefLangChange={(hrefLang) =>
                        updateNestedContentElement(
                          "linkHrefLang",
                          hrefLang,
                          index
                        )
                      }
                      onTargetChange={(target) =>
                        updateNestedContentElement("linkTarget", target, index)
                      }
                      onAnchorChange={(anchor) =>
                        updateNestedContentElement("linkAnchor", anchor, index)
                      }
                    />
                  </ModalInputWrapper>
                </div>
              </CmsGeneralConfigFrame>
              <hr />
            </React.Fragment>
          );
        }
      )}
      <ModalInputWrapper
        label={tCms("addNewElement")}
        description={tCms("nestedContent-addContentElement", {
          contentElement: tCms("pb.mgtckrsld"),
        })}
      >
        <PbButton
          onClick={() =>
            dispatch(
              cmsAddNestedContentElementThunk(
                nestedElementAttributes,
                props.cePosition,
                "content"
              )
            )
          }
          startIcon={
            <PbIcon
              name="plus-light"
              width={15}
              height={15}
              className="svg-fill-white"
            />
          }
        >
          {tCms("add")}
        </PbButton>
      </ModalInputWrapper>
    </>
  );
}
